
import axios from 'axios';
export default {
    
    async createSalaryList(params)  {
        return await axios.post(`salary_list/create` , params)
    },
    async createSalaryListList(params)  {
        return await axios.post(`salary_list/create/list` , params)
    },
    async updateSalaryListColumn(column , value , data)  {
        return await axios.put(`salary_list/update_list?${column}=${value}` , data)
    },
    async deleteSalaryListList(list)  {
        return await axios.delete(`salary_list/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportSalaryList(column , value)  {
        return await axios.get(`salary_list/report?${column}=${value}`)
    },
    async getAllSalaryList()  {
        return await axios.get(`salary_list/all`)
    },
    async getOneSalaryList(salary_list_id)  {
        return await axios.get(`salary_list/all/${salary_list_id}`)
    },
    async getSalaryListByColumn(column , value)  {
        return await axios.get(`salary_list/filter?column=${column}&value=${value}`)
    },
    async deleteSalaryList(salary_list_id)  {
        return await axios.delete(`salary_list/delete/${salary_list_id}`)
    },
    async updateSalaryList(salary_list_id , params)  {
        return await axios.put(`salary_list/update/${salary_list_id}` , params)
    }
}